import React from 'react';
import './App.css';
import { Router, Switch, Route } from 'react-router-dom';
import Search from './components/search';
import Donate from './components/donate/donate';
//import history from './components/history';
import createHistory from 'history/createBrowserHistory'

const history = createHistory();



function App() {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route path='/' component={Search} exact />
          <Route path="/donate" component={Donate} exact />
        </Switch>
      </Router>
    </>
  );
}

export default App;
