import React from "react";
import "./sortableTable.css";
import { Container, Row, Col } from "react-grid-system";

import { CSVLink } from "react-csv";
import csvDownload from "../csvDownload.png";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const CGTTable = (props) => {
  // console.log(props);
  const { items, requestSort, sortConfig } = useSortableData(
    props.cgtTransactions
  );

  // const cgtTransactions = props.cgtTransactions;
  const currentCurrency = props.cCurrency;
  const currencySymbol = props.currencySymbol;
  const histPrices = props.hPrices;

  const csvData = items.map((cgtTransactions) => {
    let price = 0;
    const dateArray = cgtTransactions.sentAt.split(`T`)[0].split(`-`);
    var transactionDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;

    if (histPrices[`${cgtTransactions.tokenName}`].length !== 0) {
      for (const element of histPrices[cgtTransactions.tokenName]) {
        if (cgtTransactions.sentAt.split(`T`)[0] === element.date) {
          price = element.prices[currentCurrency];
          break;
        }
      }
    }

    return {
      Date: transactionDate,
      "Transaction Type": cgtTransactions.transactionType,
      "Token Name": cgtTransactions.tokenName,
      Amount: cgtTransactions.amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      Price: price.toFixed(4),
      Value: (price * cgtTransactions.amount)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    };
  });

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  if (
    props.cgtTransactions[0] === undefined &&
    props.cgtTransactionsLoading === false
  ) {
    return (
      <Container>
        <Row>
          <Col
            md={12}
            style={{
              backgroundColor: "white",
              textAlign: "center",
              fontWeight: "bold",
              color: "black",
            }}
          >
            <p>
              Choose the date range to query from the datepicker above to query
              the rewards database
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            md={2}
            style={{ backgroundColor: "white", textAlign: "center" }}
          ></Col>

          <Col md={12} style={{ backgroundColor: "white", textAlign: "left" }}>
            <div
              style={{
                display: "table",
                height: "90px",
                maxWidth: "100%",
                whiteSpace: "initial",
                overflow: "hidden",
                margin: "auto",
                backgroundColor: "white",
              }}
            >
              <ul>
                <li>
                  Per epoch rewards data is only available from the time of
                  address registration.
                </li>
                <li>
                  The first row for each validator shows rewards earned up to
                  the report start date.
                </li>
                <li>
                  Records shown are daily rollups per validator of per epoch
                  recorded rewards.
                </li>
                <li>
                  The contained epochs for each day are represented as "start
                  epoch - end epoch".
                </li>
                <li>
                  Daily price is calculated based on sum of per epoch prices
                  divided by number of epochs.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else if (props.cgtTransactionsLoading === true) {
    return (
      <Row>
        <Col md={12} style={{ backgroundColor: "white", textAlign: "center" }}>
          <div
            style={{
              display: "table",
              height: "280px",
              maxWidth: "300px",
              whiteSpace: "initial",
              overflow: "hidden",
              margin: "auto",
              backgroundColor: "white",
            }}
          >
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <div
                style={{
                  textAlign: "center",
                  wordWrap: "breakWord",
                  whiteSpace: "initial",
                }}
              >
                <p style={{ color: "black", fontSize: "30px" }}>
                  Searching for records....
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  } else if (props.cgtTransactionsLoading === false) {
    return (
      <table style={{ width: "100%" }}>
        <thead style={{ padding: "2em" }}>
          <tr>
            <th style={{ textAlign: "center" }}>
              <button
                type="button"
                onClick={() => requestSort("sentAt")}
                className={getClassNamesFor("sentAt")}
              >
                Date
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={() => requestSort("transactionType")}
                className={getClassNamesFor("transactionType")}
              >
                Transaction Type
              </button>
            </th>
            <th style={{ textAlign: "center" }}>
              <button
                type="button"
                onClick={() => requestSort("tokenName")}
                className={getClassNamesFor("tokenName")}
              >
                Token Name
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={() => requestSort("amount")}
                className={getClassNamesFor("amount")}
              >
                Amount
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={() => requestSort("transactionPrice")}
                className={getClassNamesFor("transactionPrice")}
              >
                Price ({currencySymbol})
              </button>
            </th>

            <th>
              <button
                type="button"
                onClick={() => requestSort("transactionValue")}
                className={getClassNamesFor("transactionValue")}
              >
                Value ({currencySymbol})
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((cgtTransactions, index) => {
            let price = 0;
            const dateArray = cgtTransactions.sentAt.split(`T`)[0].split(`-`);
            var transactionDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;

            if (histPrices[`${cgtTransactions.tokenName}`].length !== 0) {
              for (const element of histPrices[cgtTransactions.tokenName]) {
                if (cgtTransactions.sentAt.split(`T`)[0] === element.date) {
                  price = element.prices[currentCurrency];
                  break;
                }
              }
            }

            return (
              <tr style={{ height: "30px" }} key={index}>
                <td style={{ wordBreak: "break-all", textAlign: "center" }}>
                  {transactionDate}
                </td>
                <td style={{ wordBreak: "break-all", textAlign: "center" }}>
                  {cgtTransactions.transactionType}
                </td>
                <td style={{ wordBreak: "break-all", textAlign: "center" }}>
                  {cgtTransactions.tokenName}
                </td>
                <td style={{ wordBreak: "break-all", textAlign: "center" }}>
                  {cgtTransactions.amount
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td style={{ wordBreak: "break-all", textAlign: "center" }}>
                  {price.toFixed(4)}
                </td>
                <td style={{ wordBreak: "break-all", textAlign: "center" }}>
                  {(price * cgtTransactions.amount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
            );
          })}

          <tr>
            <td style={{ textAlign: "center", borderBottom: "none" }}></td>
            <td style={{ textAlign: "center", borderBottom: "none" }}></td>
            <td style={{ textAlign: "center", borderBottom: "none" }}></td>
            <td style={{ textAlign: "center", borderBottom: "none" }}></td>
            <td style={{ textAlign: "center", borderBottom: "none" }}></td>
            <td style={{ textAlign: "center", borderBottom: "none" }}>
              <div
                style={{ textAlign: "center", borderBottom: "none" }}
                className="tooltip"
              >
                <CSVLink
                  filename={
                    "RadixPortfolio_IncomeReport_" + Date.now() + ".csv"
                  }
                  data={csvData}
                >
                  <img style={{ width: "30px" }} src={csvDownload} alt="" />
                </CSVLink>
                <span className="tooltiptext">Download CSV</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
};

export default CGTTable;
