import React from "react";
import "./donate.css";
import radixstakerlogo from "../../redCapeTransparentRed.png";


function Donate() {
  return (
    <div align="center" className="">
     
     <br></br>
     <br></br>
     <br></br>
     <p><img src={radixstakerlogo} alt="" />
     <br></br><br></br><br></br>RadixStaker hopes you like the tracker... we will continue to develop as time permits... 
     
     <br></br>
      If you like what we have built and want development to be done faster or have
      specific features you would like developed which aren't on our roadmap... 
      
      <br></br>
      Then you can donate to our development fund. 
      <br></br><br></br>
      Send radix to this address:
      <br></br>
      <strong> rdx1qsp79h2ympusvtvjvjjdx8ynzumpg75zpqrp53upkjyyecn7zkyhrfs9220uz  </strong>
      <br></br><br></br>Add a message to the transaction with the feature you would like to see built.
      </p>
    </div>
  );
}

export default Donate;
