import "../App.css";
import "./search.css";
import { Doughnut } from "react-chartjs-2";
//import { Line, Circle } from 'rc-progress';
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import moment from "moment";

import request from "sync-request";
import queryString from "query-string";
//import History from './history';
//import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "./tabs.css";

//import Big from 'big.js';
import { Container, Row, Col } from "react-grid-system";
import { setConfiguration } from "react-grid-system";
import logo from "../logo.png";
import alertExample from "../alertExample.png";
import goldClass from "../goldClass.png";
import silverClass from "../silverClass.png";
import bronzeClass from "../bronzeClass.png";
import searchButton from "../search.png";
//import getSymbolFromCurrency from 'currency-symbol-map'
import createHistory from "history/createBrowserHistory";

import CoinGecko from "coingecko-api";

import randomColor from "randomcolor";
import SortableTable from "./sortableTable";
import IncomeTable from "./incomeReport";
import RewardsSnapshot from "./rewardsSnapshot";
import UnstakeTable from "./unstakeProgress";
import CGTTable from "./cgtReport";
import Settings from "./settings";
import Alerts from "./alerts";
import ReadMe from "./readme";
import Footer from "./footer";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./reactDatepicker.css";

setConfiguration({ maxScreenClass: "xl" });
const history = createHistory();

const Search = ({ location }) => {
  //UI Variables
  const [dailyPriceUSD, setDailyPriceUSD] = useState(0);
  const [dailyPriceGBP, setDailyPriceGBP] = useState(0);
  const [dailyPriceEUR, setDailyPriceEUR] = useState(0);
  const [dailyPriceJPY, setDailyPriceJPY] = useState(0);
  const [dailyPriceCNY, setDailyPriceCNY] = useState(0);
  const [dailyPriceINR, setDailyPriceINR] = useState(0);
  const [dailyPriceAUD, setDailyPriceAUD] = useState(0);
  const [dailyPriceKRW, setDailyPriceKRW] = useState(0);
  const [historicPrices, setHistoricPrices] = useState(0);

  const [tokenPrice, setTokenPrice] = useState(0);
  const [address, setAddress] = useState("");
  const [totalRewards, setTotalRewards] = useState(0);
  //  const[totalRewardsValue,setTotalRewardsValue]=useState(0);
  const [stakedToRadixStaker, setStakedToRadixStaker] = useState(0);
  const [stakedToRadixStakerPercentage, setStakedToRadixStakerPercentage] =
    useState(0);
  const [userClass, setUserClass] = useState();
  const [userClassImage, setUserClassImage] = useState();
  const [userSummary, setUserSummary] = useState();
  const [totalStaked, setTotalStaked] = useState(0);
  const [epochDuration, setEpochDuration] = useState(0);

  const [APY, setAPY] = useState(0);
  const [totalPortfolioValue, setTotalPortfolioValue] = useState(0);
  const [totalPortfolioStake, setTotalPortfolioStake] = useState(0);
  const [validators, setValidators] = useState([]);
  const [snapshotRewards, setSnapshotRewards] = useState([]);
  const [snapshotPicker, setSnapshotPicker] = useState();

  const [incomeRewards, setIncomeRewards] = useState([]);
  const [transactionsCGT, setTransactionsCGT] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [stakesPercentages, setStakesPercentages] = useState([]);
  const [transactionFees, setTransactionFees] = useState(0);
  const [transactionsBalance, setTransactionsBalance] = useState(0);
  const [addressBalances, setAddressBalances] = useState(0);
  const [positionTotalStaked, setPositionTotalStaked] = useState(0);
  const [backgroundColors, setBackgroundColors] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [snapshotDate, setSnapshotDate] = useState(new Date());

  const [incomeRewardsLoading, setIncomeRewardsLoading] = useState(false);
  const [incomeStartDate, setIncomeStartDate] = useState(new Date());
  const [incomeEndDate, setIncomeEndDate] = useState(new Date());
  const [cgtTransactionsLoading, setCgtTransactionsLoading] = useState(false);
  const [CGTStartDate, setCGTStartDate] = useState(new Date());
  const [CGTEndDate, setCGTEndDate] = useState(new Date());
  const [currencies, setCurrencies] = useState([
    {
      currency: "USD",
      symbol: "$",
    },
    {
      currency: "GBP",
      symbol: "£",
    },
    {
      currency: "EUR",
      symbol: "€ ",
    },
    {
      currency: "AUD",
      symbol: "$",
    },
    {
      currency: "JPY",
      symbol: "¥",
    },
    {
      currency: "CNY",
      symbol: "¥",
    },
    {
      currency: "INR",
      symbol: "₹",
    },
    {
      currency: "KRW",
      symbol: "₩",
    },
  ]);
  const [pageloading, setPageLoading] = useState(true);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currentCurrency, setCurrentCurrency] = useState("");
  const [currency, setCurrency] = useState("");
  const [totalDelegatedStakeNextEpoch, setTotalDelegatedStakeNextEpoch] =
    useState(0);
  const [delegatorStakedXRD, setDelegatorStakedXRD] = useState(0);

  // check if address or currency is changed and reset to new values
  function addressChanged(ev) {
    setAddress(ev.target.value);
  }

  function currencyChanged(ev) {
    setCurrency(ev.target.value);
    getPrice(ev.target.value);
  }

  //function to determine system local for setting date display formats
  function getBrowserLocales(options = {}) {
    const defaultOptions = {
      languageCodeOnly: false,
    };

    const opt = {
      ...defaultOptions,
      ...options,
    };

    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;

    if (!browserLocales) {
      return undefined;
    }

    return browserLocales.map((locale) => {
      const trimmedLocale = locale.trim();

      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
  } //end function

  async function getTransactions(address) {
    const body = {
      network_identifier: {
        network: "mainnet",
      },
      account_identifier: {
        address: address,
      },
      cursor: "0",
      limit: 10,
    };

    let transactions = [];
    let cursor = "";
    try {
      do {
        const data = (
          await axios.post(
            "https://gatewayus.radixportfolio.info/account/transactions",
            body,
            {
              maxContentLength: "Infinity",
              maxBodyLength: "Infinity",
            }
          )
        ).data;
        cursor = data.next_cursor;
        if (cursor) {
          body.cursor = cursor;
        } else {
          body.cursor = "";
        }
        transactions.push(...data.transactions);
      } while (cursor);
      return transactions;
    } catch (error) {
      console.log("error at getTransaction function");
    }
  }
  //set dateFormat for use in datePickers
  let locale = getBrowserLocales();
  let dateFormat = "d/M/yyyy";
  if (locale[0] == "en-US") {
    dateFormat = "M/d/yyyy";
  }
  //console.log(dateFormat);
  //console.info(locale[0]);

  //function for converting dateTime to date in UTC
  function convertToUTCDate(dateToConvert) {
    var convertedDate = "";
    // console.log("1. DateToConvert :"+dateToConvert);
    var date = new Date(dateToConvert);
    var d = date.getUTCDate();
    // console.log("2. day "+d);
    var m = date.getUTCMonth() + 1;
    // console.log("3. month "+m);
    var y = date.getUTCFullYear();
    var dateString = m + "/" + d + "/" + y;
    // console.log("4. dateString "+dateString);
    var convertedDate = new Date(dateString);
    //  console.log("5. convertedDate "+ convertedDate);
    return convertedDate;
  }

  //function for converting dateTime to date in UTC
  function convertToLocalDate(dateToConvert) {
    var convertedDate = "";
    //console.log("1. DateToConvert :"+dateToConvert);
    var convertedDate = new Date(dateToConvert).toDateString();
    // console.log("1.1 ConvertedDate "+ convertedDate);
    return convertedDate;
  }

  function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  function addDaysToCurrentDate(date, days) {
    let currentDate = new Date(date);
    return new Date(currentDate.setDate(currentDate.getDate() + days));
  }

  function minusDaysFromCurrentDate(date, days) {
    let currentDate = new Date(date);
    return new Date(currentDate.setDate(currentDate.getDate() - days));
  }

  // search rewards for snapshot section
  function searchSnapshotRewards() {
    let start_month = parseInt(snapshotDate.getUTCMonth() + 1);
    let start_day = snapshotDate.getUTCDate();
    let end_month = parseInt(snapshotDate.getUTCMonth() + 1);
    let end_day = snapshotDate.getUTCDate();
    if (start_month < 10) {
      start_month = "0" + start_month;
    }
    if (start_day < 10) {
      start_day = "0" + start_day;
    }
    if (end_month < 10) {
      end_month = "0" + end_month;
    }
    if (end_day < 10) {
      end_day = "0" + end_day;
    }

    let s = snapshotDate.getUTCFullYear() + "-" + start_month + "-" + start_day;
    let e = s;
    let link = "";
    if (userClass == "Bronze") {
      link =
        "https://PRODradixportfolioUS.azurewebsites.net/api/Validators?start=" +
        s +
        "&end=" +
        e +
        "&searchedAddress=rdx1qspnmq4crd97km25vreqa69uzdn04t8mjg0h5l53r4z5qsfelda93cg9s5jz7";
    } else {
      link =
        "https://PRODradixportfolioUS.azurewebsites.net/api/Validators?start=" +
        s +
        "&end=" +
        e +
        "&searchedAddress=" +
        address;
      // console.log(link);
    }
    console.log(link);

    axios
      .get(link)
      .then((response) => {
        let rewards = response.data;
        //sort by epoch in ascending order
        let sortedRewards = rewards.sort((a, b) =>
          a.epoch > b.epoch ? 1 : -1
        );
        let sortedRewards2 = sortedRewards;

        const uniqueValidators = [
          ...new Set(sortedRewards.map((item) => item.validator)),
        ];

        let rewardsDif = [];

        uniqueValidators.map((uniqueValidator, index) => {
          let last_index = 0;
          //     let topReward=0;

          sortedRewards.map((innerreward, innerIndex) => {
            //  if(incomeStartDateMinus=innerreward.date){
            //    console.log("HERE");
            //  }

            if (uniqueValidator == innerreward.validator) {
              //this is first most element
              if (last_index == 0) {
                rewardsDif.push(innerreward);
                last_index = 1;
              } else {
                let previousreward = 0;

                sortedRewards2.map((r, i) => {
                  if (
                    r.validator == innerreward.validator &&
                    r.epoch < innerreward.epoch
                  ) {
                    previousreward = r.rewards;
                    //   console.log("previous rewards is: "+previousreward)
                  }
                });
                var validatorreward = {
                  searchedAddress: innerreward.address,
                  epoch: innerreward.epoch, //new field
                  validator: innerreward.validator,
                  validatorName: innerreward.validatorName, //validatorInfo1.name,
                  rewards: innerreward.rewards - previousreward,
                  date: innerreward.date,
                  usd: innerreward.usd,
                  gbp: innerreward.gbp,
                  eur: innerreward.eur,
                  jpy: innerreward.jpy,
                  cny: innerreward.cny,
                  inr: innerreward.inr,
                  aud: innerreward.aud,
                  krw: innerreward.krw, // new field
                };
                rewardsDif.push(validatorreward);
                //              console.info(validatorreward);
              }
            } else {
              //console.log(uniqueValidator+'=='+innerreward.validator)
            }
          });
        });

        //  console.log(rewards);
        //console.log(rewardsDif);
        setSnapshotRewards(rewardsDif);
        setSnapshotPicker(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //################################################################################################################
  // search rewards for income section

  //end searchIncomeRewards function
  async function searchIncomeRewards() {
    let start_month = parseInt(incomeStartDate.getUTCMonth() + 1);
    let start_day = incomeStartDate.getUTCDate();
    let end_month = parseInt(incomeEndDate.getUTCMonth() + 1);
    let end_day = incomeEndDate.getUTCDate();
    let incomeStartDateMinus = minusDaysFromCurrentDate(incomeStartDate, 1);
    let start_monthMinus = parseInt(incomeStartDateMinus.getUTCMonth() + 1);
    let start_dayMinus = incomeStartDateMinus.getUTCDate();
    setIncomeRewardsLoading(true);

    if (start_month < 10) {
      start_month = "0" + start_month;
    }
    if (start_day < 10) {
      start_day = "0" + start_day;
    }
    if (end_month < 10) {
      end_month = "0" + end_month;
    }
    if (end_day < 10) {
      end_day = "0" + end_day;
    }

    //and again for the day before
    if (start_monthMinus < 10) {
      start_monthMinus = "0" + start_monthMinus;
    }
    if (start_dayMinus < 10) {
      start_dayMinus = "0" + start_dayMinus;
    }

    // const rewardsArray = [];

    let s =
      incomeStartDateMinus.getUTCFullYear() +
      "-" +
      start_monthMinus +
      "-" +
      start_dayMinus;
    let e = incomeEndDate.getUTCFullYear() + "-" + end_month + "-" + end_day;
    let link = "";
    const days = 1000 * 60 * 60 * 24;

    // eslint-disable-next-line no-extend-native
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    let start = new Date(s);
    let end = new Date(e);
    let breakLoop = false;
    const dataArray = [];

    while (!breakLoop) {
      if (Math.floor((incomeEndDate - incomeStartDate) / days) <= 10) {
        breakLoop = true;
      } else {
        end = start.addDays(10);
      }
      if (end.getTime() > incomeEndDate.getTime()) {
        end.setDate(incomeEndDate.getDate());
        breakLoop = true;
      }

      if (userClass != "Gold") {
        link =
          "https://PRODradixportfolioUS.azurewebsites.net/api/Validators?start=" +
          start.toISOString().split("T")[0] +
          "&end=" +
          end.toISOString().split("T")[0] +
          "&searchedAddress=rdx1qspnmq4crd97km25vreqa69uzdn04t8mjg0h5l53r4z5qsfelda93cg9s5jz7";
      } else {
        link =
          "https://PRODradixportfolioUS.azurewebsites.net/api/Validators?start=" +
          start.toISOString().split("T")[0] +
          "&end=" +
          end.toISOString().split("T")[0] +
          "&searchedAddress=" +
          address;
      }
      console.log(link);
      // record timestamp on query
      var today1 = new Date();
      var date1 =
        today1.getFullYear() +
        "-" +
        (today1.getMonth() + 1) +
        "-" +
        today1.getDate();
      var time1 =
        today1.getHours() +
        ":" +
        today1.getMinutes() +
        ":" +
        today1.getSeconds();
      var dateTime1 = date1 + " " + time1;

      console.log(dateTime1);

      const rewards = await axios
        .get(link)
        .then((response) => {
          let rewards = response.data;
          return rewards;
        }) //end then response
        .catch((error) => {
          console.log(error);
        }); // end then error
      dataArray.push(...rewards);
      start = end.addDays(1);
    }

    let rewards = dataArray;
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;

    console.log(dateTime);
    let sortedRewards = rewards.sort((a, b) => (a.epoch > b.epoch ? 1 : -1));

    let dailyRewards = 0;
    let startEpoch = 0;
    let endEpoch = 0;
    let epochRange = 0;
    let previousDayRewards = 0;
    let endOfDayRewards = 0;
    let rewardDate = 0;
    let usd = 0;
    let gbp = 0;
    let eur = 0;
    let jpy = 0;
    let cny = 0;
    let inr = 0;
    let aud = 0;
    let krw = 0;
    let epochsInDay = 0;
    let validatorName = 0;

    const uniqueValidators = [
      ...new Set(sortedRewards.map((item) => item.validator)),
    ];
    const uniqueDates = [
      ...new Set(
        sortedRewards.map((item) => convertToLocalDate(item.date).toString())
      ),
    ];
    const rewardsByDay = [];

    //   console.info(uniqueDates);
    //   console.info(sortedRewards);
    uniqueValidators.forEach((uValidator) => {
      let indexZero = 0;
      let indexZeroEpoch = 0;
      let indexZeroDate = 0;

      uniqueDates.forEach((uDate) => {
        startEpoch = 0;

        sortedRewards.map((item, index) => {
          if (
            uValidator == item.validator &&
            Date.parse(convertToLocalDate(uDate).toString()) ==
              Date.parse(convertToLocalDate(item.date).toString())
          ) {
            if (indexZero == 0) {
              indexZeroEpoch = item.epoch;
              indexZeroDate = item.date;
              indexZero = 1;
            }

            if (
              Date.parse(convertToLocalDate(uDate).toString()) ==
              Date.parse(convertToLocalDate(indexZeroDate)).toString()
            ) {
              endEpoch = item.epoch;
              validatorName = item.validatorName;
              dailyRewards = item.rewards;
              rewardDate = "Up To: " + item.date;
              epochRange = "Up To: " + endEpoch;
              previousDayRewards = item.rewards;
              endOfDayRewards = item.rewards;
              //epochsInDay = 1;

              usd = "NA";
              gbp = "NA";
              eur = "NA";
              jpy = "NA";
              cny = "NA";
              inr = "NA";
              aud = "NA";
              krw = "NA";
            } else {
              if (startEpoch == 0) {
                startEpoch = endEpoch + 1;
              }
              endEpoch = item.epoch;
              validatorName = item.validatorName;
              endOfDayRewards = item.rewards;
              dailyRewards = endOfDayRewards - previousDayRewards;
              rewardDate = item.date.substring(0, 10);
              epochRange = startEpoch + "-" + endEpoch;
              usd = usd + item.usd;
              gbp = gbp + item.gbp;
              eur = eur + item.eur;
              jpy = jpy + item.jpy;
              cny = cny + item.cny;
              inr = inr + item.inr;
              aud = aud + item.aud;
              krw = krw = item.krw;
              epochsInDay++;
            }
          }
        }); //end sortedRewards loop

        var rewardsByDate = {
          rewardDate: rewardDate,
          validator: uValidator,
          validatorName: validatorName,
          startEpoch: startEpoch,
          endEpoch: endEpoch,
          epochRange: epochRange,
          epochsInDay: epochsInDay,
          previousDayRewards: previousDayRewards,
          endOfDayRewards: endOfDayRewards,
          dailyRewards: dailyRewards,
          usd: usd / epochsInDay,
          gbp: gbp / epochsInDay,
          eur: eur / epochsInDay,
          jpy: jpy / epochsInDay,
          cny: cny / epochsInDay,
          inr: inr / epochsInDay,
          aud: aud / epochsInDay,
          krw: krw / epochsInDay,
        };

        rewardsByDay.push(rewardsByDate);

        usd = 0;
        gbp = 0;
        eur = 0;
        jpy = 0;
        cny = 0;
        inr = 0;
        aud = 0;
        krw = 0;
        epochsInDay = 0;
        startEpoch = 0;
        previousDayRewards = endOfDayRewards;
      }); //end uniqueDates loop
    }); //end uniqueValidators loop

    setIncomeRewards(rewardsByDay);
    setIncomeRewardsLoading(false);
  }
  //################################################################################################################

  // calculate aquisitions and disposals for CGT report
  function searchTransactions() {
    var a = address;
    setCgtTransactionsLoading(true);

    getTransactions(a).then((response) => {
      var transactions = response;
      var transCGT = [];

      if (transactions.length > 0) {
        var totalTokenTransfer = 0;
        var transactionsBalanceTotal = 0;

        //loop through validator transactions
        for (let index2 in transactions) {
          for (let index in transactions[index2].actions) {
            if (transactions[index2].actions[index].type === "TransferTokens") {
              var type = "NA";
              var amount = 0;
              var present = false;

              totalTokenTransfer =
                totalTokenTransfer +
                transactions[index2].actions[index].amount.value /
                  1000000000000000000;

              if (
                transactions[index2].actions[index].to_account.address === a
              ) {
                type = "acquisition";
                amount =
                  transactions[index2].actions[index].amount.value /
                  1000000000000000000;
                present = true;

                transactionsBalanceTotal =
                  transactionsBalanceTotal +
                  transactions[index2].actions[index].amount.value /
                    1000000000000000000;
              } else if (
                transactions[index2].actions[index].from_account.address === a
              ) {
                type = "disposal";
                amount =
                  -transactions[index2].actions[index].amount.value /
                  1000000000000000000;
                present = true;

                transactionsBalanceTotal =
                  transactionsBalanceTotal -
                  transactions[index2].actions[index].amount.value /
                    1000000000000000000;
              }

              /// CGT stuff

              //          let dateOfTransaction=transaction.sentAt.substring(0,10);
              let tokenSplit =
                transactions[index2].actions[
                  index
                ].amount.token_identifier.rri.split("_");
              let tokenName = tokenSplit[0];

              //set CGT record

              var cgt = {
                sentAt: transactions[index2].transaction_status.confirmed_time,
                transactionType: type,
                tokenName: tokenName,
                amount: amount,
                //     usd:dailyPriceUSD,
                //     gbp:dailyPriceGBP,
                //     eur:dailyPriceEUR,
                //     jpy:dailyPriceJPY,
                //     cny:dailyPriceCNY,
                //     inr:dailyPriceINR,
                //     aud:dailyPriceAUD,
                //     krw:dailyPriceKRW,
                //     transactionFees:transaction.fee/1000000000000000000
              };

              if (present) {
                transCGT.push(cgt);
                //  console.info(cgt);
              }
              present = false;
            } //end TokenTransfar
          }
        }
        let transactionsCopy = transCGT.filter((item) => {
          var d = new Date(item.sentAt);
          return moment(d.toLocaleDateString("en-US")).isBetween(
            CGTStartDate.toLocaleDateString("en-US"),
            CGTEndDate.toLocaleDateString("en-US"),
            null,
            "[]"
          );
        });

        async function getHistoricPrices(startDate, endDate, tokenName) {
          try {
            const http = require("axios");
            //ibad the devradixfunction only has prices since the start of the year so use the PROD link at https://radixnodejsau.azurewebsites.net/api/prices as the URL if you want full prices
            let URL =
              "https://PRODradixportfolioUS.azurewebsites.net/api/prices?startDate=" +
              startDate +
              "&endDate=" +
              endDate +
              "&tokenName=" +
              tokenName;

            const response = await http.get(URL);
            let tokenPrices = response.data;
            return tokenPrices;
          } catch (error) {
            console.error("There was an error!", error);
            console.log("Error response :" + error.response);
            console.log("Error response :" + error.message);
          }
        } // end getHistoricalPrices

        async function getPricesByToken() {
          //setup dates for use in moment comparison and API call
          let startYear = "2021";
          let startMonth = "8";
          let startDay = "8";

          //let startYear = CGTStartDate.getUTCFullYear();
          //let startMonth = CGTStartDate.getUTCMonth()+1;
          if (startMonth < 10) {
            startMonth = "0" + startMonth;
          }
          //let startDay = CGTStartDate.getUTCDate();
          if (startDay < 10) {
            startDay = "0" + startDay;
          }
          let endDateCGT = new Date();
          let endYear = endDateCGT.getUTCFullYear(); //CGTEndDate.getUTCFullYear();
          let endMonth = endDateCGT.getUTCMonth() + 1; //CGTEndDate.getUTCMonth()+1;
          if (endMonth < 10) {
            endMonth = "0" + endMonth;
          }
          let endDay = endDateCGT.getUTCDate(); //CGTEndDate.getUTCDate();
          if (endDay < 10) {
            endDay = "0" + endDay;
          }

          let startD = startYear + "-" + startMonth + "-" + startDay;
          let endD = endYear + "-" + endMonth + "-" + endDay;
          let listOfPrices = {};

          for (let count in transactionsCopy) {
            listOfPrices[transactionsCopy[count].tokenName] =
              await getHistoricPrices(
                startD,
                endD,
                transactionsCopy[count].tokenName
              );
          }

          return listOfPrices;
        }

        getPricesByToken().then((result) => {
          setHistoricPrices(result);
          setFilteredTransactions(transactionsCopy);
          setCgtTransactionsLoading(false);
        });
      }
    });
  } //end searchTransactions function

  const CoinGeckoClient = new CoinGecko();

  const getCurrencies = async () => {
    let coins = await CoinGeckoClient.simple.supportedVsCurrencies();
    setCurrencies(coins.data);
    //  console.info(coins.data);
  };

  const getPrice = async (currency) => {
    try {
      let data = await CoinGeckoClient.simple.price({
        ids: ["radix"],
        vs_currencies: [currency],
      });

      //console.info(data);

      //get XRD price
      var radix = data.data["radix"];

      for (var key in radix) {
        if (radix.hasOwnProperty(key)) {
          setTokenPrice(radix[key]);

          let c = currencies.filter((obj) => {
            return obj.currency === key.toUpperCase();
          });

          setCurrencySymbol(c[0].symbol);
          setCurrentCurrency(c[0].currency);
        }
      }
    } catch (error) {
      setPageLoading(true);
    }
  };

  {
    /* 
function getValidatorsInfo(searchaddress){
    const validatorInfoBody=
    {
      "jsonrpc": "2.0",
      "method": "validators.lookup_validator",
      "params": {
          "validatorAddress": searchaddress
      },
      "id": 1
  }

 /// let validatorInfo;

return  axios.post('https://archiveus.radixportfolio.info/archive',validatorInfoBody)
 .then(response=>{

  return response.data.result;

 });
} // end getValidatorsInfo function

*/
  }

  useEffect(() => {
    // Update the document title using the browser API

    const params = new URLSearchParams(location.search);
    // get the q param
    const searchAddress = params.get("address");
    const c = params.get("currency");

    var currencyFromUrl = "USD";

    if (c) {
      if (
        currencies.some((currency) => currency.currency === c.toUpperCase())
      ) {
        getPrice(c.toUpperCase());
        currencyFromUrl = c.toUpperCase();

        setCurrency(c.toUpperCase());
        /* vendors contains the element we're looking for */
      } else {
        getPrice("USD");
        setCurrency("USD");
      }
    } else {
      getPrice("USD");
      setCurrency("USD");
    }

    if (searchAddress) {
      setAddress(searchAddress);
      searchClicked("", searchAddress, currencyFromUrl);
    }
  }, []);

  useEffect(() => {
    // Update the document title using the browser API
  }, [currencies]);

  useEffect(() => {
    // Update the document title using the browser API
    //  if(tokenPrice==14){
    //  }
    //  else{
    setPageLoading(false);
    // }
  }, [tokenPrice]);

  //Unstake Report code starts
  const [unstakeValidators, setUnstakeValidators] = useState([]);
  const [totalUnstakeAmount, setTotalUnstakeAmount] = useState(0);

  useEffect(() => {
    if (validators.length > 0) {
      let unstakePositions = [];

      var getUnstakePositionsBody = {
        network_identifier: {
          network: "mainnet",
        },
        account_identifier: {
          address: address,
        },
      };

      
      axios
        .post(
          "https://gatewayus.radixportfolio.info/account/unstakes",
          getUnstakePositionsBody
        )
        .then((response) => {
          unstakePositions = [
            ...response.data.unstakes,
            ...response.data.pending_unstakes,
          ];

          let unstakedValidators = [];

          unstakePositions.map((unstakeValidator) => {
            validators.map((validator) => {
              if (
                validator.validator ==
                unstakeValidator.validator_identifier.address
              ) {
                //           console.log(unstakeValidator.validator);
                var unstakeValidatorArray = {
                  validatorName: validator.validatorName,
                  validator: unstakeValidator.validator_identifier.address,
                  amount:
                    unstakeValidator.unstaking_amount.value /
                    1000000000000000000,
                  epochsUntil: unstakeValidator.epochs_until_unlocked,
                };
                unstakedValidators.push(unstakeValidatorArray);
              }
            });
            setUnstakeValidators(unstakedValidators);

            let unStakeAmount = unstakedValidators
              .map((validator) => validator.amount)
              .reduce((prev, next) => prev + next);
            setTotalUnstakeAmount(unStakeAmount);
          });
        });
    }
  }, [validators]);

  // Unstake report code ends

  var searchClicked = async (ev, addressFromUrl, currencyFromUrl) => {
    if (address || addressFromUrl) {
      var a = address ? address : addressFromUrl;
      var c = currency ? currency : currencyFromUrl;

      history.push("/?address=" + a + "&currency=" + c);

      setShow(false);
      setLoading(true);
      setUnstakeValidators([]);

      getTransactions(a)
        .then((response) => {
          var transactions = response;
          var transCGT = [];

          if (transactions.length > 0) {
            var validators = [];
            var validatorsRewards = [];

            var totalPortfolioStake = 0;
            var totalPortfolioValue = 0;
            var totalRewards = 0;
            var totalTokenTransfer = 0;
            var DelegatedStakeNextEpochTotal = 0;

            var transactionFeesTotal = 0;
            var transactionsBalanceTotal = 0;

            var nextepoch = false;

            var totalStakedToValidator = 0;
            var totalRewardsToValidator = 0;
            var xrdBalances = 0;

            //loop through validator transactions
            transactions.map((transaction, index) => {
              try {
                transactionFeesTotal =
                  transactionFeesTotal +
                  transactions[index].fee_paid.value / 1000000000000000000;
                if (transaction.actions[0].type == "StakeTokens") {
                  // console.log('Stake Tokens');
                  var exists = false;
                  validators.map((validator, index) => {
                    if (
                      validator.validator ==
                      transaction.actions[0].to_validator.address
                    ) {
                      validators[index].totalStaked =
                        validators[index].totalStaked +
                        transaction.actions[0].amount.value /
                          1000000000000000000;
                      exists = true;

                      if (validator.hasOwnProperty("postitionTotalStaked")) {
                        validators[index].postitionTotalStaked =
                          validators[index].postitionTotalStaked + 0;
                      } else {
                        // console.log('no property');
                        validators[index].postitionTotalStaked = 0;
                      }
                    }
                  });

                  if (!exists) {
                    var validator = {
                      validator: transaction.actions[0].to_validator.address,
                      totalStaked:
                        transaction.actions[0].amount.value /
                        1000000000000000000,
                      postitionTotalStaked: 0,
                      stakePositionAmount: 0,
                      totalDelegatedStake: 0,

                      color: randomColor(),
                      validatorName: "", //validatorInfo1.name,
                      validatorUptime: "", // validatorInfo1.uptimePercentage,
                      validatorProposalsMissed: "", // validatorInfo1.proposalsMissed,
                      validatorURL: "", // validatorInfo1.infoURL,
                      validatorFee: "", //validatorInfo1.validatorFee,
                      validatorOwnStake: "", //validatorInfo1.ownerDelegation,
                      inNextEpoch: false,
                      rewards: 0,
                      value: 0,
                      APY: 0,
                      APYMultiplier: 0,
                    };
                    validators.push(validator);
                  }
                } //end stakeTokens transactions
                else if (transaction.actions[0].type == "UnstakeTokens") {
                  var exists = false;
                  validators.map((validator, index) => {
                    if (
                      validator.validator ==
                      transaction.actions[0].from_validator.address
                    ) {
                      // console.log('before unstaked '+validators[index].totalStaked);
                      validators[index].totalStaked =
                        validators[index].totalStaked -
                        transaction.actions[0].amount.value /
                          1000000000000000000;
                      // console.log('after unstaked '+validators[index].totalStaked);
                      exists = true;
                      if (validator.hasOwnProperty("postitionTotalStaked")) {
                        // console.log('has property');
                        validators[index].postitionTotalStaked =
                          validators[index].postitionTotalStaked + 0;
                      } else {
                        // console.log('no property');
                        validators[index].postitionTotalStaked = 0;
                      }
                    }
                  });
                  if (!exists) {
                    var validator = {
                      validator: transaction.actions[0].from_validator.address,
                      totalStaked:
                        -transaction.actions[0].amount.value /
                        1000000000000000000,
                      postitionTotalStaked: 0,
                      stakePositionAmount: 0,
                      totalDelegatedStake: 0,
                      color: randomColor(),
                      validatorName: "", //validatorInfo1.name,
                      validatorUptime: "", // validatorInfo1.uptimePercentage,
                      validatorProposalsMissed: "", // validatorInfo1.proposalsMissed,
                      validatorURL: "", // validatorInfo1.infoURL,
                      validatorFee: "", //validatorInfo1.validatorFee,
                      validatorOwnStake: "", //validatorInfo1.ownerDelegation,
                      inNextEpoch: false,
                      rewards: 0,
                      value: 0,
                      APY: 0,
                      APYMultiplier: 0,
                    };
                    validators.push(validator);
                    nextepoch = false;
                  }
                } //end unstakeTokens transactions
              } catch (ex) {
                // console.log(ex);
              }
            }); //end CGT code

            //########## GET ACOUNT BALANCES ########################
            var totalTokenBalances = 0;

            var balancesBody = {
              network_identifier: {
                network: "mainnet",
              },
              account_identifier: {
                address: a,
              },
            };

            var balancesRes = request(
              "POST",
              "https://gatewayus.radixportfolio.info/account/balances",
              {
                json: balancesBody,
              }
            );

            var balancesJson = JSON.parse(balancesRes.getBody("utf8"));

            let tokens = balancesJson.account_balances.liquid_balances;

            tokens.map((token, item) => {
              let str = token.token_identifier.rri;
              if (str.startsWith("xrd")) {
                totalTokenBalances =
                  totalTokenBalances + token.value / 1000000000000000000;
              }
            });

            var newTotalStaked = 0;

            //########## GET STAKE POSITIONS ########################

            var newTotalStakedBody = {
              network_identifier: {
                network: "mainnet",
              },
              account_identifier: {
                address: a,
              },
            };

            var newTotalStakedRes = request(
              "POST",
              "https://gatewayus.radixportfolio.info/account/stakes",
              {
                json: newTotalStakedBody,
              }
            );

            var newTotalStakedJson = JSON.parse(
              newTotalStakedRes.getBody("utf8")
            );
            let results = newTotalStakedJson;
            let staked2RadixStaker = 0;
            results.stakes.map((result, item) => {
              newTotalStaked =
                newTotalStaked +
                result.delegated_stake.value / 1000000000000000000;

              //calculate the total staked to radixStaker for use later in userClass
              if (
                result.validator_identifier.address ==
                "rv1qd0060jej9x0upkp6fdrednv8ct2yvdf2ydxm5a9t9ea63tp09c6u0ev6t8"
              ) {
                staked2RadixStaker =
                  staked2RadixStaker +
                  result.delegated_stake.value / 1000000000000000000;
                //  console.log("1. stakedToRadixStaker is set to: "+staked2RadixStaker);
                setStakedToRadixStaker(staked2RadixStaker);
                //  console.log(" at the time of setting sToRS "+stakedToRadixStaker+" and value sent"+staked2RadixStaker);
              }

              validators.map((validator, index) => {
                if (
                  validator.validator == result.validator_identifier.address
                ) {
                  validators[index].stakePositionAmount =
                    validators[index].stakePositionAmount +
                    result.delegated_stake.value / 1000000000000000000;
                }
              });
            });

            //########## GET NEXT EPOCH ########################
            const nextEpochBody = {
              network_identifier: {
                network: "mainnet",
              },
            };

            var nextEpochRes = request(
              "POST",
              "https://gatewayus.radixportfolio.info/validators",
              {
                json: nextEpochBody,
              }
            );

            var nextEpochJsonRes = JSON.parse(nextEpochRes.getBody("utf8"));
            let nextEpochValidators = nextEpochJsonRes.validators;

            //sort array by descending order of totalDelegatedStake
            let sortedNextEpochValidators = nextEpochValidators.sort((a, b) =>
              a.stake.value / 1000000000000000000 <
              b.stake.value / 1000000000000000000
                ? 1
                : -1
            );
            sortedNextEpochValidators.map((result, outerIndex) => {
              //sum only top validators from epoch api
              if (outerIndex <= 99) {
                DelegatedStakeNextEpochTotal =
                  DelegatedStakeNextEpochTotal +
                  result.stake.value / 1000000000000000000;
              }

              validators.map((validator, index) => {
                if (
                  validator.validator == result.validator_identifier.address
                ) {
                  //In Next epoch to check only in top 100
                  if (outerIndex <= 99) {
                    validators[index].inNextEpoch = true;
                  }

                  validators[index].validatorName = result.properties.name;
                  validators[index].validatorUptime =
                    result.info.uptime.uptime_percentage;
                  validators[index].validatorProposalsMissed =
                    result.info.uptime.proposals_missed;
                  validators[index].validatorURL = result.properties.url;
                  validators[index].validatorFee =
                    result.properties.validator_fee_percentage;
                  validators[index].validatorOwnStake =
                    result.info.owner_stake.value;
                  validators[index].validatorPosition = outerIndex + 1;
                }
              });
            });

            setValidators(validators);
            console.info(validators);
            totalPortfolioStake = validators
              .map((validator) => validator.totalStaked)
              .reduce((prev, next) => prev + next);
            setTotalPortfolioStake(totalPortfolioStake);

            setTotalPortfolioValue(totalPortfolioStake);
            setTransactionFees(transactionFeesTotal);
            setTransactionsBalance(transactionsBalanceTotal);
            totalRewards = newTotalStaked - totalPortfolioStake;
            setTotalRewards(totalRewards);
            setPositionTotalStaked(newTotalStaked);
            //  console.log("10 Here newtotalStaked +++"+newTotalStaked);
            //  console.log("10 Here tPStake"+ totalPortfolioStake);
            //  console.log("10 stakedToRadixStaker "+stakedToRadixStaker);
            //address parameter is to allow
            let userDetails = uClass(
              staked2RadixStaker,
              newTotalStaked,
              address
            );
            setUserClass(userDetails.userClass);
            setUserClassImage(userDetails.userClassImage);
            setStakedToRadixStakerPercentage(
              userDetails.stakedToRadixStakerPercentage
            );
            //   console.log(userDetails.userClass);
            //   console.log(userDetails.stakedToRadixStakerPercentage);
            setAPY((totalPortfolioStake / totalRewards) * 100);
            setAddressBalances(totalTokenBalances);
            setTotalDelegatedStakeNextEpoch(DelegatedStakeNextEpochTotal);
            // setDelegatorStakedXRD(newTotalStaked);

            var stakesPercentages = [];
            var backgroundColors = [];

            validators.map((validator, item) => {
              stakesPercentages.push(
                (validator.stakePositionAmount /
                  (totalPortfolioStake + totalRewards)) *
                  100
              );
              backgroundColors.push(validator.color);

              if (validator.validatorPosition == null) {
                validator.validatorPosition = "-";
              }
              console.log(validator.validatorName);
              console.log(validator.totalStaked);
              console.log(totalPortfolioStake + totalRewards);
              console.log(totalPortfolioStake);

              validator["stakePercentage"] =
                (validator.stakePositionAmount /
                  (totalPortfolioStake + totalRewards)) *
                100;
              validator["rewards"] =
                validator.stakePositionAmount - validator.totalStaked;
              validator["value"] = validator.totalStaked;
              if (validator.validatorUptime <= 98) {
                validator["APYMultiplier"] = 0;
              } else {
                validator["APYMultiplier"] =
                  ((100 - (100 - validator.validatorUptime) * 50) / 100) *
                  (100 - validator.validatorFee);
                // console.log(validator.APYMultiplier);
                let currentValidatorAPY = parseFloat(
                  ((validator.stakePositionAmount /
                    DelegatedStakeNextEpochTotal) *
                    validator.APYMultiplier *
                    (18784 * 23077)) /
                    validator.stakePositionAmount
                ).toFixed(2);

                if (isNaN(currentValidatorAPY)) {
                  validator["APY"] = 0;
                } else if (currentValidatorAPY) {
                  validator["APY"] = currentValidatorAPY;
                } else {
                  validator["APY"] = 0;
                }
              }
            });

            setStakesPercentages(stakesPercentages);
            setBackgroundColors(backgroundColors);
            setError(false);
            setShow(true);
            setShowWelcomeMessage(false);
            setLoading(false);
          } else {
            setValidators([]);
            setTotalPortfolioStake(0);
            setTotalPortfolioValue(0);
            setStakesPercentages(0);
            setTotalUnstakeAmount(0);
            setError(true);
            setErrorMessage("Oops! No Transactions Found");
            setLoading(false);
            setShow(false);
            setShowWelcomeMessage(false);
            console.log("1. Is this triggered on first load?");
          }
        })
        .catch((error) => {
          if (error.message == "Network Error") {
            setErrorMessage("Network Error");
          } else if (
            error.message == "Reduce of empty array with no initial value"
          ) {
            setErrorMessage("This address has no stake positions.");
          } else {
            setValidators([]);
            setTotalPortfolioStake(0);
            setTotalPortfolioValue(0);
            setStakesPercentages(0);
            setError(true);
            setErrorMessage(
              "Invalid address entered. Please check the address and try again."
            );
            console.log("2. is this triggered on first load", error);
            console.log(error);
          }
          setLoading(false);
          setShow(false);
          setShowWelcomeMessage(false);
        });

      //######################

      //#######################################
      // THIS CODE NEEDS TO BE FIXED... ISSUES WITH ASYNC

      //comment for Ibad
      //this function retrieves the historic prices from the cosmosdb database (i wrote an azure function that does tihs)
      // i need to add these to the array of transactions so that we have the prices for the various currencies available to add to
      // the array. problem for me is that the function returns a promise and i struggled to add them to the array of transacitons as

      //get historical prices function

      //########################################

      function uClass(stakedToRadixStaker, stakedToAllValidators, address) {
        // calculate the user class
        let stakedToRadixStakerPercentage =
          (stakedToRadixStaker / stakedToAllValidators) * 100;
        //  console.log("5. In userClass function staked to RS "+stakedToRadixStaker);
        //  console.log("5. In userClass function staked to ALL "+stakedToAllValidators);
        //  console.log("5. In userClass function percentage is "+stakedToRadixStakerPercentage);
        console.log(address);
        let userClassImage = bronzeClass;
        let userClass = "Bronze"; //default user class is bronze
        console.log("Default userClass is Bronze");
        if (
          stakedToRadixStakerPercentage >= 10 ||
          stakedToRadixStaker >= 1000000 ||
          address ==
            "rdx1qspx494cqmyperaj3j4fyc3sdpurlkepk9ss7gcazwrnyw3fegxxy0cay0den"
        ) {
          userClass = "Gold"; //gold is 10% or 500k staked to radixstaker
          userClassImage = goldClass;
          console.log("Gold? " + userClass);
        } else if (
          stakedToRadixStakerPercentage >= 5 ||
          stakedToRadixStaker >= 500000
        ) {
          userClass = "Silver"; //silver is 5% or 250k staked to radixstaker
          userClassImage = silverClass;
          console.log("Silver? " + userClass);
        }
        console.log(
          "userClass is " +
            userClass +
            " with this percentage staked: " +
            stakedToRadixStakerPercentage
        );
        return {
          userClass,
          userClassImage,
          stakedToRadixStakerPercentage,
        };
      } // end function

      {
        /*
    function usersSummary(uClass){
       let userSummary1 = "";
       let userSummary2 = "";
    // 
    //   console.log(uClass);
       //switch(userClass){
          if(userClass == 'Gold'){
          //   console.log("User summary: Gold");
             userSummary1 = (stakedToRadixStaker-(positionTotalStaked*.1)).toFixed(0)+" XRD can be unstaked before losing status Gold status";
             userSummary2 = (stakedToRadixStaker-(positionTotalStaked*.05)).toFixed(0)+" XRD can be unstaked before losing Silver status";
            }
          else if(userClass == 'Silver'){
          //   console.log("User summary: Silver");
             userSummary1 = ((positionTotalStaked*.1)-stakedToRadixStaker).toFixed(0)+" XRD stake required to attain Gold status";
             userSummary2 = (stakedToRadixStaker-(positionTotalStaked*.05)).toFixed(0)+" XRD can be unstaked before losing Silver status";
          
          }
          else if(userClass == 'Bronze'){
              userSummary1 = ((positionTotalStaked*.05)-stakedToRadixStaker).toFixed(0)+" XRD stake required to attain Silver status";
              userSummary2 = ((positionTotalStaked*.1)-stakedToRadixStaker).toFixed(0)+" XRD stake required to attain Gold status";
              
          }
          else{
          //  console.log("Error setting userClass")
          }
          setUserSummary(userSummary);
    }  //end usersSummary
  */
      }

      function numberWithCommas(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      setTimeout(function () {
        //alert("this is the message"+stakedToRadixStakerPercentage+ " "+stakedToRadixStaker);
      }, 60000);

      //BUTTON CLICK FUNCTIONS
      function incomeRewardsGetData() {
        searchIncomeRewards();
      }

      //######################

      //Snapshot Code
      let snapshotMonth = parseInt(snapshotDate.getUTCMonth() + 1);
      let snapshotDay = snapshotDate.getUTCDate();
      if (snapshotMonth < 10) {
        snapshotMonth = "0" + snapshotMonth;
      }
      if (snapshotDay < 10) {
        snapshotDay = "0" + snapshotDay;
      }

      let s =
        snapshotDate.getUTCFullYear() + "-" + snapshotMonth + "-" + snapshotDay;
      let e = s;
      let link = "";

      if (userClass == "Bronze") {
        link =
          "https://PRODradixportfolioUS.azurewebsites.net/api/Validators?start=" +
          s +
          "&end=" +
          e +
          "&searchedAddress=rdx1qspnmq4crd97km25vreqa69uzdn04t8mjg0h5l53r4z5qsfelda93cg9s5jz7";
      } else {
        link =
          "https://PRODradixportfolioUS.azurewebsites.net/api/Validators?start=" +
          s +
          "&end=" +
          e +
          "&searchedAddress=" +
          a;
      }
      // console.log(link);

      axios
        .get(link)
        .then((response) => {
          //rewards is an array of validator records
          let snapshotJSON = response.data;
          //sort by epoch in ascending order
          let sortedSnapshot = snapshotJSON.sort((a, b) =>
            a.epoch > b.epoch ? 1 : -1
          );
          let sortedSnapshot2 = sortedSnapshot;

          const uniqueValidators = [
            ...new Set(sortedSnapshot.map((item) => item.validator)),
          ];
          let sortedSnapshotRewardsDif = [];
          uniqueValidators.map((uniqueValidator, index) => {
            let last_index = 0;
            let previousdate = 0;

            sortedSnapshot.map((innerreward, innerIndex) => {
              if (uniqueValidator == innerreward.validator) {
                //        console.log("innerward is: "+uniqueValidator);

                //this is first most element use it for the first row of the reward table
                if (last_index == 0) {
                  sortedSnapshotRewardsDif.push(innerreward);
                  last_index = 1;
                } else {
                  let previousreward = 0;
                  sortedSnapshot2.map((r, i) => {
                    //       console.log(r.validator);
                    if (
                      r.validator == innerreward.validator &&
                      r.epoch < innerreward.epoch
                    ) {
                      previousreward = r.rewards;
                      previousdate = r.date;
                      //
                      //                 console.log("previous rewards is: "+previousreward)
                    }
                  });

                  var validatorSnapshot = {
                    epoch: innerreward.epoch, //new field
                    validator: innerreward.validator,
                    validatorName: innerreward.validatorName, //validatorInfo1.name,
                    rewards: innerreward.rewards - previousreward,
                    epochDuration:
                      parseInt(
                        (new Date(innerreward.date).getTime() / 1000).toFixed(0)
                      ) -
                      parseInt(
                        (new Date(previousdate).getTime() / 1000).toFixed(0)
                      ),
                    date: innerreward.date,
                    usd: innerreward.usd,
                    gbp: innerreward.gbp,
                    eur: innerreward.eur,
                    jpy: innerreward.jpy,
                    cny: innerreward.cny,
                    inr: innerreward.inr, // new field
                    aud: innerreward.aud,
                    krw: innerreward.krw, // new field
                  };
                  sortedSnapshotRewardsDif.push(validatorSnapshot);
                  setEpochDuration(validatorSnapshot.epochDuration);
                }
              } else {
              }
            });
          });
          sortedSnapshotRewardsDif = sortedSnapshotRewardsDif.sort((a, b) =>
            a.date > b.date ? 1 : -1
          );
          setSnapshotRewards(sortedSnapshotRewardsDif);
          setSnapshotPicker(false);
        })
        .catch((error) => {
          console.log(error);
        });
      //end snapshot code
    }
  };

  return (
    <div className="App">
      <Container>
        {pageloading && (
          <div>
            <div
              style={{
                display: "table",
                height: "400px",
                overflow: "hidden",
                margin: "auto",
                backgroundColor: "white",
              }}
            >
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <div style={{ textAlign: "center" }}>
                  <p style={{ color: "black", fontSize: "30px" }}>
                    Loading.....
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {!pageloading && (
          <div>
            {/* Header */}

            <Row>
              <Col
                md={4}
                lg={3}
                style={{
                  margin: "auto",
                  marginTop: "-11px",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <a href="https://radixstaker.com" target="_blank">
                  <img src={logo} />
                </a>
              </Col>
              <Col
                xs={10}
                sm={11}
                md={7}
                lg={6}
                style={{
                  marginTop: "0px",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <input
                  type="text"
                  placeholder="Delegator Address"
                  className="search-field"
                  id="nameField"
                  onChange={addressChanged}
                  value={address}
                />
                <button
                  type="submit"
                  className="search-button"
                  onClick={searchClicked}
                >
                  <img src={searchButton} />
                </button>
              </Col>

              <Col
                xs={2}
                sm={1}
                md={1}
                lg={1}
                style={{
                  textAlign: "center",
                  marginTop: "0px",
                  marginLeft: "-10px",
                  marginRight: "10px",
                }}
              >
                <select
                  id="currencyDropdown"
                  className="select-list"
                  onChange={currencyChanged}
                  value={currency}
                >
                  {currencies.map((currency, item) => {
                    return (
                      <option value={currency.currency}>
                        {currency.currency}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col
                md={12}
                lg={1}
                style={{
                  textAlign: "center",
                  overflow: "hidden",
                  backgroundColor: "white",
                  padding: "0px",
                  marginTop: "-9px",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <p style={{ color: "black", fontSize: "16px" }}>
                  XRD Price: {currencySymbol}
                  {tokenPrice}
                </p>
              </Col>
              <Col
                md={12}
                lg={1}
                style={{
                  textAlign: "center",
                  marginTop: "-6px",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <div
                  style={{ textAlign: "center", borderBottom: "none" }}
                  className="tooltipUserSummary"
                >
                  <img src={userClassImage} className="coinImage" />
                  <span className="tooltiptext">User class: {userClass}</span>
                </div>
              </Col>
            </Row>

            {/* Loading  */}

            {!show && loading && (
              <Row>
                <Col
                  md={12}
                  style={{ backgroundColor: "white", textAlign: "center" }}
                >
                  <div
                    style={{
                      display: "table",
                      height: "280px",
                      maxWidth: "300px",
                      whiteSpace: "initial",
                      overflow: "hidden",
                      margin: "auto",
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{ display: "table-cell", verticalAlign: "middle" }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          wordWrap: "breakWord",
                          whiteSpace: "initial",
                        }}
                      >
                        <p style={{ color: "black", fontSize: "30px" }}>
                          Searching.....
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontSize: "11px",
                            wordWrap: "breakWord",
                            whiteSpace: "normal",
                          }}
                        >
                          {address}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {/* Welcome Message */}

            {!loading && showWelcomeMessage && (
              <div>
                <Row>
                  <Col md={12} style={{ backgroundColor: "white" }}>
                    <div
                      style={{
                        display: "table",
                        height: "280px",
                        overflow: "hidden",
                        margin: "auto",
                        backgroundColor: "white",
                      }}
                    >
                      <div
                        style={{
                          display: "table-cell",
                          verticalAlign: "middle",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <p style={{ color: "black", fontSize: "30px" }}>
                            Enter Delegator Address and Press Search Button
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            {/* Response Message */}

            {!loading && error && (
              <div>
                <Row>
                  <Col
                    md={12}
                    style={{ marginTop: "30dp", backgroundColor: "white" }}
                  >
                    <div
                      style={{
                        display: "table",
                        height: "400px",
                        overflow: "hidden",
                        margin: "auto",
                        backgroundColor: "white",
                      }}
                    >
                      <div
                        style={{
                          display: "table-cell",
                          verticalAlign: "middle",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <p style={{ color: "black", fontSize: "30px" }}>
                            Oops! {errorMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            {/* Results */}

            {!error && show && (
              <div>
                {/* Average Values */}
                <Row>
                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    style={{
                      overflow: "hidden",
                      backgroundColor: "white",
                      padding: "20px",
                    }}
                  >
                    <p style={{ color: "black", fontSize: "18px" }}>
                      Total&nbsp;Staked
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      {positionTotalStaked
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {currencySymbol}
                      {(tokenPrice * (totalPortfolioStake + totalRewards))
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    style={{
                      overflow: "hidden",
                      backgroundColor: "white",
                      padding: "20px",
                    }}
                  >
                    <p style={{ color: "black", fontSize: "18px" }}>
                      Principal&nbsp;Stake
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      {totalPortfolioStake
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {currencySymbol}
                      {(tokenPrice * totalPortfolioStake)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    style={{
                      overflow: "hidden",
                      backgroundColor: "white",
                      padding: "20px",
                    }}
                  >
                    <p style={{ color: "black", fontSize: "18px" }}>
                      Staking&nbsp;Rewards
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      {totalRewards
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {currencySymbol}
                      {(tokenPrice * totalRewards)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    style={{
                      overflow: "hidden",
                      backgroundColor: "white",
                      padding: "20px",
                    }}
                  >
                    <p style={{ color: "black", fontSize: "18px" }}>
                      Unstake&nbsp;Pending
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      {totalUnstakeAmount
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {currencySymbol}
                      {(totalUnstakeAmount * tokenPrice)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    style={{
                      overflow: "hidden",
                      backgroundColor: "white",
                      padding: "20px",
                    }}
                  >
                    <p style={{ color: "black", fontSize: "18px" }}>
                      Wallet&nbsp;Balance
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      {addressBalances
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {currencySymbol}
                      {(addressBalances * tokenPrice)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    style={{
                      overflow: "hidden",
                      backgroundColor: "white",
                      padding: "20px",
                    }}
                  >
                    <p style={{ color: "black", fontSize: "18px" }}>
                      Total&nbsp;Portfolio
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      {(
                        addressBalances +
                        totalPortfolioStake +
                        totalRewards +
                        totalUnstakeAmount
                      )
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {currencySymbol}
                      {(
                        (addressBalances +
                          totalPortfolioStake +
                          totalRewards +
                          totalUnstakeAmount) *
                        tokenPrice
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col
                    md={12}
                    style={{
                      overflow: "hidden",
                      backgroundColor: "white",
                      paddingLeft: "20px",
                      textAlign: "left",
                    }}
                  >
                    <Tabs>
                      <TabList>
                        <Tab style={{ fontSize: "20px", marginLeft: "0px" }}>
                          Validators
                        </Tab>
                        <Tab style={{ fontSize: "20px" }}>Unstaking Status</Tab>
                        <Tab style={{ fontSize: "20px" }}>Rewards Snapshot</Tab>
                        <Tab style={{ fontSize: "20px" }}>Staking Alerts</Tab>
                        <Tab style={{ fontSize: "20px" }}>Income Report</Tab>
                        <Tab style={{ fontSize: "20px" }}>CGT Report</Tab>
                        {/*  <Tab style={{fontSize:"20px"}}>Settings</Tab>  */}
                        <Tab style={{ fontSize: "20px" }}>Read Me</Tab>
                      </TabList>

                      {/*Validator Section*/}
                      <TabPanel>
                        <Col md={12} style={{ textAlign: "center" }}>
                          <p>
                            This report shows rewards earned per validator along
                            with key metrics for validator performance.{" "}
                            <br></br>
                            This Bronze service is provided free of charge to
                            the Radix community.
                          </p>
                        </Col>

                        <Row
                          style={{
                            backgroundColor: "white",
                            padding: "0px",
                            marginTop: "20px",
                          }}
                        >
                          <Col md={12} style={{ position: "relative" }}>
                            <div
                              className="graph"
                              style={{ margin: "auto", position: "relative" }}
                            >
                              <p
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                Stake Distribution
                              </p>
                              <Doughnut
                                style={{ position: "absolute" }}
                                data={{
                                  datasets: [
                                    {
                                      label: "Rainfall",
                                      backgroundColor: backgroundColors,

                                      data: stakesPercentages,
                                    },
                                  ],
                                }}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: true,
                                  title: {
                                    display: true,
                                    text: "Validators Staked",
                                    fontSize: 20,
                                    fontColor: "black",
                                  },
                                }}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            lineHeight: "normal",
                            padding: "10px",
                            marginTop: "0px",
                            marginBottom: "20px",
                            overflowX: "auto",
                          }}
                        >
                          <Col md={12}>
                            <SortableTable
                              products={validators}
                              currencySymbol={currencySymbol}
                              tPrice={tokenPrice}
                              totalDelegatedStakeNextEpoch={
                                totalDelegatedStakeNextEpoch
                              }
                            />
                          </Col>
                        </Row>
                      </TabPanel>

                      {/* Unstake Report Section*/}
                      <TabPanel style={{ minHeight: "400px" }}>
                        <Col md={12} style={{ textAlign: "center" }}>
                          <p>
                            This report shows unstake requests currently in
                            progress and epochs and time estimate to completion.{" "}
                            <br></br>
                            This Bronze service is provided free of charge to
                            the Radix community.
                          </p>
                        </Col>
                        <UnstakeTable
                          epochDuration={epochDuration}
                          unstakeValidators={unstakeValidators}
                        />
                      </TabPanel>

                      {/* Rewards Snapshot Section*/}
                      <TabPanel style={{ minHeight: "400px" }}>
                        <Col md={12} style={{ textAlign: "center" }}>
                          <p>
                            This report shows per epoch, per validator rewards
                            for the selected date. Use the date picker to view a
                            different date<br></br>
                            This Silver service requires 5% your staked Radix to
                            be delegated to Radix Staker. <br></br>
                          </p>
                        </Col>
                        <Row>
                          <Col md={12} style={{ textAlign: "center" }}>
                            <DatePicker
                              wrapperClassName="datePicker"
                              dateFormat={dateFormat}
                              selected={snapshotDate}
                              onChange={(date) => setSnapshotDate(date)}
                            />
                            <button
                              type="submit"
                              className="search-button-snapshotRewards"
                              onClick={searchSnapshotRewards}
                            >
                              <img src={searchButton} />
                            </button>
                          </Col>

                          {userClass == "Bronze" && (
                            <Col md={12} style={{ textAlign: "center" }}>
                              <p style={{ color: "red", fontWeight: "700" }}>
                                Your user class does not currently provide you
                                access to this feature.
                                <br></br>The data returned via the data picker
                                and shown below is not your data and is for
                                illustration only.
                                <br></br>See the "Read Me" tab for details on
                                additional stake required to reach Silver user
                                status which unlocks this feature.
                              </p>
                            </Col>
                          )}
                        </Row>

                        <RewardsSnapshot
                          cCurrency={currentCurrency.toLowerCase()}
                          userClass={userClass}
                          snapshotPicker={snapshotPicker}
                          snapshotRewards={snapshotRewards}
                          currencySymbol={currencySymbol}
                        />
                      </TabPanel>

                      {/*Alerts Section*/}

                      <TabPanel
                        style={{ minHeight: "400px", paddingBottom: "20px" }}
                      >
                        <Row>
                          <Col md={12} style={{ textAlign: "center" }}>
                            <p>
                              Use this section to setup monitoring to send alert
                              notifications for any key changes to your
                              delegated validators. <br></br>
                              This Silver service requires 5% your staked Radix
                              to be delegated to Radix Staker. <br></br>
                            </p>
                          </Col>

                          {userClass == "Bronze" && (
                            <Col md={12} style={{ textAlign: "center" }}>
                              <p style={{ color: "red", fontWeight: "700" }}>
                                Your user class does not currently provide you
                                access to this feature.
                                <br></br>The image below is an example of the
                                alerts you receive when this feature is
                                available. Alerts are also provided over
                                Telegram..
                                <br></br>See the "Read Me" tab for details on
                                additional stake required to reach Silver user
                                status which unlocks this feature.
                              </p>
                              <p>
                                <img
                                  style={{ width: "700px" }}
                                  src={alertExample}
                                />
                              </p>
                            </Col>
                          )}
                          {userClass != "Bronze" && (
                            <Col md={12} style={{ textAlign: "center" }}>
                              <Alerts {...{ address }} />
                            </Col>
                          )}
                        </Row>
                      </TabPanel>

                      {/*Income Report Section*/}

                      <TabPanel style={{ minHeight: "400px" }}>
                        <Col md={12} style={{ textAlign: "center" }}>
                          <p>
                            This report shows daily rewards earned for all
                            validators for the purposes of income tax
                            calculations. <br></br>
                            This Gold service requires 10% of your staked Radix
                            to be delegated to Radix Staker.
                          </p>
                        </Col>

                        <Row>
                          <Col md={12} style={{ textAlign: "center" }}>
                            <DatePicker
                              wrapperClassName="datePicker"
                              dateFormat={dateFormat}
                              selected={incomeStartDate}
                              onChange={(date) => setIncomeStartDate(date)}
                            />
                            <DatePicker
                              wrapperClassName="datePicker"
                              dateFormat={dateFormat}
                              selected={incomeEndDate}
                              onChange={(date) => setIncomeEndDate(date)}
                            />
                            <button
                              type="submit"
                              className="search-button-incomeRewards"
                              onClick={searchIncomeRewards}
                            >
                              <img src={searchButton} />
                            </button>
                          </Col>
                        </Row>
                        {userClass != "Gold" && (
                          <Col md={12} style={{ textAlign: "center" }}>
                            <p style={{ color: "red", fontWeight: "700" }}>
                              Your user class does not currently provide you
                              access to this feature.
                              <br></br>The data returned via the data picker and
                              shown below is not your data and is for
                              illustration only.
                              <br></br>See the the "Read Me" tab for details on
                              additional stake required to reach Gold user
                              status which unlocks this feature.
                            </p>
                          </Col>
                        )}

                        <IncomeTable
                          userClass={userClass}
                          cCurrency={currentCurrency.toLowerCase()}
                          incomeRewards={incomeRewards}
                          incomeRewardsLoading={incomeRewardsLoading}
                          currencySymbol={currencySymbol}
                          tPrice={tokenPrice}
                        />
                      </TabPanel>

                      {/*CGT Report Section*/}

                      <TabPanel
                        style={{ minHeight: "400px", paddingBottom: "20px" }}
                      >
                        <Row>
                          <Col md={12} style={{ textAlign: "center" }}>
                            <p>
                              This report shows daily rewards and aquisition and
                              disposal of all Radix tokens held in the searched
                              address/wallet for purposes of Capital Gains Tax
                              calculations.<br></br>
                              This Gold service requires 10% of your staked
                              Radix to be delegated to Radix Staker.
                            </p>
                          </Col>

                          <Col md={12} style={{ textAlign: "center" }}>
                            <DatePicker
                              wrapperClassName="datePicker"
                              dateFormat={dateFormat}
                              selected={CGTStartDate}
                              onChange={(date) => setCGTStartDate(date)}
                            />
                            <DatePicker
                              wrapperClassName="datePicker"
                              dateFormat={dateFormat}
                              selected={CGTEndDate}
                              onChange={(date) => setCGTEndDate(date)}
                            />
                            <button
                              type="submit"
                              className="search-button-incomeRewards"
                              onClick={searchTransactions}
                            >
                              <img src={searchButton} />
                            </button>
                          </Col>
                        </Row>

                        <CGTTable
                          hPrices={historicPrices}
                          cCurrency={currentCurrency.toLowerCase()}
                          cgtTransactions={filteredTransactions}
                          cgtTransactionsLoading={cgtTransactionsLoading}
                          currencySymbol={currencySymbol}
                        />
                      </TabPanel>

                      {/*Settings Section*/}
                      {/*

<TabPanel style={{minHeight:'400px',paddingBottom:'20px'}}>
  <Row>
    <Col md={12} style={{textAlign:'center'}}>
      <p>In order to access the advanced features of this tracker you need to add your 
      address to the database to begin tracking or no data is available.
      </p>

    </Col>
  </Row>
<Settings />
</TabPanel>

*/}

                      {/*Read Me Section*/}

                      <TabPanel
                        style={{ minHeight: "400px", paddingBottom: "20px" }}
                      >
                        <Row>
                          <Col md={12} style={{ textAlign: "center" }}></Col>
                        </Row>
                        <ReadMe
                          positionTotalStaked={positionTotalStaked}
                          userClass={userClass}
                          userSummary={userSummary}
                          userClassImage={userClassImage}
                          stakedToRadixStaker={stakedToRadixStaker}
                          stakedToRadixStakerPercentage={
                            stakedToRadixStakerPercentage
                          }
                        />
                      </TabPanel>
                    </Tabs>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default Search;
