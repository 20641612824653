import "./style.css";

function Intro() {
  return (
<div style={{display: 'table', height: '90px',maxWidth:'100%', whiteSpace: 'initial',overflow: 'hidden',margin:'auto',backgroundColor:'white'}}>
         
  
         <p style={{fontWeight : "bold"}}>Alerts are currently not enabled. To enable Alerts:</p>
         <ol>
         <li>Open the Telegram Bot <a href="https://t.me/RadixPortfolioAlertsBot" target="_blank" rel="noreferrer">here </a></li>  
         <li>Send <strong>/start</strong> inside the bot to retrieve a link to add your address for monitoring</li> 
         <li>Click the link provided by the bot and then register your address</li>
         <li>Alerting is now enabled for your staked Validators</li>
         <li>You will receive an alert in the Telegam bot for key Validator state changes</li>
         <li>Alerts will also be visible in this page</li>
        
        </ol> 
         <p></p> 
         <p style={{fontWeight : "bold"}}>Alerts are then generated for the following changes to your validator set:</p>
         <ul>
         <li>Any staked Validator's uptime percentage falls by &gt; 0.25% </li>  
         <li>Any staked Validator node runner deregisters their validator or it falls out of the top 100</li> 
         <li>Staked validator's percentage fee increases by ANY amount</li>
         </ul> 
    
      </div> 

  );
}

export default Intro;
{/* 

<div className="intro">
      <div className="container">
        <h2>
          Alerts are generated for the following changes to your validator set:
        </h2>
        <ul>
          <li>Staked validator uptime percentage falls by &gt; 0.25% </li>
          <li>Staked validator node runner deregisters their validator</li>
          <li>Staked validator fee increases by any amount</li>
        </ul>
      </div>
      <div className="container">
        <h3>To enable Alerts:</h3>
        <ol>
          <li>Open the Telegram Bot here @RadixPortfolioAlerts</li>
          <li>
            Send /start to this bot to retrieve a link to add your address for
            monitoring
          </li>
          <li>Click the link provided by the bot to register your address</li>
        </ol>
      </div>
    </div>


*/}