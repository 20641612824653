import React from 'react';
//import './rewardsSnapshot.css';
import './sortableTable.css';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const RewardsSnapshot = (props) => {
 // console.log("rewardsSnapshot loaded");
  const { items, requestSort, sortConfig } = useSortableData(props.snapshotRewards);
  const currentCurrency=props.cCurrency;
  const snapshotPicker=props.snapshotPicker;
  const userClass=props.userClass;
  const currencySymbol=props.currencySymbol;
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  //console.log(snapshotPicker);
  //console.log(userClass);
  if (snapshotPicker === false && props.userClass === "Bronze"){
    return "";
  }
  else{
  return (
    <table style={{width:'100%'}}> 
      <thead style={{padding:'0em'}}>
        <tr>
        
          <th style={{textAlign: 'center'}} >
            <button
              type="button" 
              onClick={() => requestSort('date')}
              className={getClassNamesFor('date')}
            >
              Date 
            </button>
          </th>
          <th >
            <button
              type="button" 
            >
              Time
            </button>
          </th>
          <th >
            <button
              type="button" 
              onClick={() => requestSort('epoch')}
              className={getClassNamesFor('epoch')}
            >
              Epoch
            </button>
          </th>
          <th>
            <button
              type="button" 
              onClick={() => requestSort('validatorName')}
              className={getClassNamesFor('validatorName')}
            >
            Validator Name
            </button>
          </th>
          <th>
            <button 
              type="button" 
              onClick={() => requestSort('rewards')}
              className={getClassNamesFor('rewards')}
            >
              Amount (XRD)
            </button>
          </th>

          <th >
            <button
              type="button" 
            >
              Price ({currencySymbol})
            </button>
          </th>
          <th>
            <button
              type="button" 
            >
              Value ({currencySymbol})
            </button>
          </th>

                
        </tr>
      </thead>
      <tbody>
        {items.map((validator,index) => {

          var rewardDate=new Date(validator.date);
          var date=rewardDate.toLocaleDateString();
          var time=rewardDate.toLocaleTimeString();
        
            return(
            
         <tr style={{height:'30px'}} key={index}>
         <td style={{textAlign: 'center'}}>{date}</td>
         <td style={{textAlign: 'center'}}>{time}</td>
         <td style={{textAlign: 'center'}}>{validator.epoch}</td>
         <td style={{textAlign: 'center'}}>{validator.validatorName.substring(0,24)}</td>
         <td style={{textAlign: 'center'}}>{(validator.rewards).toFixed(4)}</td>
         <td style={{textAlign: 'center'}}>{(validator[currentCurrency])}</td>
         <td style={{textAlign: 'center'}}>{(validator[currentCurrency]* validator.rewards).toFixed(2)}</td>
      
       
         </tr>
        )})}
      </tbody>
    </table>
  );
  }
};

export default RewardsSnapshot;
