import React from 'react';
import './rewardsSnapshot.css';
import './toolTip.css';
import { Container, Row, Col } from 'react-grid-system';

import { CSVLink, CSVDownload } from "react-csv";
import csvDownload from "../csvDownload.png"
//import DownloadIcon from '@mui/icons-material/Download';
//import { borderBottom } from '@mui/system';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const RewardsTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.incomeRewards);
  const currentCurrency=props.cCurrency;
  const currencySymbol=props.currencySymbol;
 // const tokenPrice=props.tPrice;
 // const totalDelegatedStakeNextEpoch=props.totalDelegatedStakeNextEpoch;
  const incomeRewards=props.incomeRewards;
  const incomeRewardsLoading=props.incomeRewardsLoading;
  const userClass=props.userClass;

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
console.log(userClass);
console.log(incomeRewardsLoading);
  if (props.incomeRewards[0] === undefined && incomeRewardsLoading===false){

    return(

      <Container>
      <Row>
            <Col md={12} style={{backgroundColor:'white',textAlign:'center', fontWeight: 'bold', color:'black'}}>
      
              <p>Choose the date range to query from the datepicker above to query the rewards database</p> 
            
              </Col>
              </Row>
            <Row>
            <Col md={2} style={{backgroundColor:'white',textAlign:'center'}}>
            </Col>
            
            
                    <Col md={12} style={{backgroundColor:'white',textAlign:'left'}}>
            <div style={{display: 'table', height: '90px',maxWidth:'100%', whiteSpace: 'initial',overflow: 'hidden',margin:'auto',backgroundColor:'white'}}>
                   
            <ul>
          <li>Per epoch rewards data is only available from the time of address registration.</li> 
          <li>The first row for each validator shows rewards earned up to the report start date.</li> 
          <li>Records shown are daily rollups per validator of per epoch recorded rewards.</li>
          <li>The contained epochs for each day are represented as "start epoch - end epoch".</li>
          <li>Daily price is calculated based on sum of per epoch prices divided by number of epochs.</li>
        </ul>
            </div>
            </Col>
            </Row>
      </Container>
      
      
        );
      

  }
  else if(props.incomeRewardsLoading===true){
  
    return(
  
      <Row>
      <Col md={12} style={{backgroundColor:'white',textAlign:'center'}}>
    
        <div style={{display: 'table', height: '280px',maxWidth:'300px', whiteSpace: 'initial',overflow: 'hidden',margin:'auto',backgroundColor:'white'}}>
        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
        <div style={{textAlign:'center',wordWrap: 'breakWord',whiteSpace: 'initial'}}>
        <p style={{color:'black',fontSize:'30px'}}>Searching for records....</p>
        
    
       </div>
       </div>
      </div>
      </Col>
      </Row>
    );  

  }
  else if (props.incomeRewardsLoading==false){
  
  return (
    <table style={{width:'100%'}}> 
      <thead style={{padding:'2em'}}>
        <tr>
        
          <th style={{textAlign: 'center'}}>
            <button
              type="button"
              onClick={() => requestSort('rewardDate')}
              className={getClassNamesFor('rewardDate')}
            >
              Rewards Date
            </button>
          </th>
          <th >
            <button
              type="button"
              onClick={() => requestSort('epochRange')}
              className={getClassNamesFor('epochRange')}
            >
              Epochs
            </button>
          </th>
          <th>
            <button
              type="button"
              onClick={() => requestSort('validatorName')}
              className={getClassNamesFor('validatorName')}
            >
            Validator
            </button>
          </th>
          <th>
            <button 
              type="button"
              onClick={() => requestSort('dailyRewards')}
              className={getClassNamesFor('dailyRewards')}
            >
              Amount (XRD)
            </button>
          </th>

          <th >
            <button
              type="button" 
            >
              Avg Price ({currencySymbol})
            </button>
          </th>
          <th>
            <button
              type="button"
            >
              Value ({currencySymbol})
            </button>
          </th>

                
        </tr>
      </thead>
      <tbody>
        {items.map((incomeData,index) => {
          let date = 0;
          let price = 0;
          let value = 0;
          if (new Date(incomeData.rewardDate).toLocaleDateString() === "Invalid Date"){
            date = "To Date"; //lessThan+" "+new Date(datePart).toLocaleDateString();
            price = "NA";
            value = "NA";
          }
          else{
            date = new Date(incomeData.rewardDate).toLocaleDateString();
            price = (incomeData[currentCurrency]).toFixed(4);
            value = (incomeData[currentCurrency]*incomeData.dailyRewards).toFixed(2);
          }
          return(
            
            <tr style={{height:'30px'}} key={index}>
            <td style={{textAlign: 'center'}}>{date}</td>
            <td style={{textAlign: 'center'}}>{incomeData.epochRange}</td>
            <td style={{textAlign: 'center'}}>{incomeData.validatorName}</td>
            <td style={{textAlign: 'center'}}>{(incomeData.dailyRewards).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td style={{textAlign: 'center'}}>{price}</td>
            <td style={{textAlign: 'center'}}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            </tr>
          )
        })}
        <tr>
          <td style={{textAlign: 'center', borderBottom: 'none'}}></td>
          <td style={{textAlign: 'center', borderBottom: 'none'}}></td>
          <td style={{textAlign: 'center', borderBottom: 'none'}}></td>
          <td style={{textAlign: 'center', borderBottom: 'none'}}></td>
          <td style={{textAlign: 'center', borderBottom: 'none'}}></td>
          <td style={{textAlign: 'center', borderBottom: 'none'}}>
          <div style={{textAlign: 'center', borderBottom: 'none'}} className="tooltip">
          <CSVLink  filename={"RadixPortfolio_IncomeReport_"+Date.now()+".csv"} data={incomeRewards}><img style={{width: '30px'}} src={csvDownload} alt=""/></CSVLink>
          <span className="tooltiptext">Download CSV</span>
          </div>
            </td></tr>
      </tbody>
    </table>
  );
  }
};

export default RewardsTable;
